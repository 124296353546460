<template>
  <div>
    <!--Modal AddEditUsuario-->
    <div v-if="edit.mostrarModal">
      <add-edit-usuario
        v-model="edit.mostrarModal"
        :usuario-id="edit.usuarioId"
        @onSave="handleAddEditUsuarioSaved"
      ></add-edit-usuario>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form>
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label">
                  <i class="fas fa-filter"></i> Filtros
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label class="font-weight-bold">Nombre</label>
                  <b-form-input
                    id="input-1"
                    v-model="filter.nombre"
                    required
                    placeholder="Nombre"
                  ></b-form-input>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Correo Electrónico</label>
                  <b-form-input
                    id="input-2"
                    v-model="filter.correoElectronico"
                    required
                    placeholder="Correo Electrónico"
                  ></b-form-input>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Nro. Documento</label>
                  <b-form-input
                    id="input-3"
                    v-model="filter.numeroDocumento"
                    required
                    placeholder="Nro. Documento"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="card-footer padding-t-b">
              <div class="col-md-12">
                <a
                  href="#"
                  class="btn btn-primary font-weight-bold mr-2 alignment-right"
                  @click.prevent="fetchUsuarios()"
                  ><i class="fa fa-search"></i> Buscar</a
                >
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                <i class="fa fa-fw fa-users"></i> Usuarios
              </h3>
            </div>
            <div class="card-toolbar">
              <a
                href="#"
                class="btn btn-primary font-weight-bold mr-2"
                @click.prevent="doAddEditUsuario()"
                ><i class="la la-plus"></i> Nuevo</a
              >
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-hover"
              >
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Nro. Documento</th>
                    <th>
                      Nombre Completo
                    </th>
                    <th>
                      Correo Electrónico
                    </th>
                    <th>Rol</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="usuario in usuarios" :key="usuario.usuarioId">
                    <td>
                      {{ usuario.userName }}
                    </td>
                    <td>
                      <span v-if="usuario.esBloqueado"
                        ><i class="la la-lock text-danger"></i
                      ></span>
                      <span class="font-weight-bold">
                        {{ usuario.tipoDocumento }} :
                      </span>
                      {{ usuario.numeroDocumento }}
                    </td>
                    <td>
                      {{ usuario.nombreCompleto }}
                    </td>
                    <td>
                      {{ usuario.correoElectronico }}
                    </td>
                    <td>
                      {{ usuario.rolNombre }}
                    </td>
                    <td>
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                          href="#"
                          @click.prevent="doAddEditUsuario(usuario.usuarioId)"
                          ><span class="navi-icon"
                            ><i class="la la-pen text-dark"></i> Editar</span
                          ></b-dropdown-item
                        >
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cell-width-10 {
  width: 10%;
}

.cell-width-60 {
  width: 60%;
}

.cell-width-20 {
  width: 20%;
}

.alignment-right {
  float: right;
}

.padding-t-b {
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import addEditUsuario from "@/components/user/add-edit-user";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "list-users",
  components: {
    addEditUsuario,
  },
  props: {},
  data: () => ({
    usuarios: [],
    edit: {
      mostrarModal: false,
      usuarioId: null,
    },
    pagination: {
      pageSize: 10,
    },
    filter: {
      nombre: "",
      correoElectronico: "",
      numeroDocumento: "",
      rolId: null,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    doAddEditUsuario: function(usuarioId) {
      this.edit.mostrarModal = true;
      this.edit.usuarioId = usuarioId;
    },
    handleAddEditUsuarioSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchUsuarios();
    },
    fetchUsuarios: function() {
      var model = {
        ...this.pagination,
        ...this.filter,
      };

      //alert(JSON.stringify(model));

      this.$http
        .post(this.apiRoute("User", "ListUsuarios"), model)
        .then(function(response) {
          this.usuarios = response.body.data.items;
          this.pagination = response.body.data.pagination;
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {},
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios" }]);
    this.fetchUsuarios();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
