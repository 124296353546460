<template>
  <b-modal id="modal-add-edit-usuario" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="mostrarModal" dialog-class="modal-dialog-scrollable" v-if="mostrarModal" @close="handleModalClose">
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"><i class="la la-building"></i> {{ usuarioId ? "Editar" : "Agregar" }} usuario </span>
      </div>
    </template>
    <b-form id="frm-add-edit-usuario">
      <h4>DATOS DE ACCESO:</h4>
      <div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold">Usuario (Código de acceso)</label>
          <b-form-input id="input-4" v-model="usuario.userName" required placeholder=""></b-form-input>
          <p class="text-muted ">Para iniciar sesión el usuario deberá utilizar este dato.</p>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Contraseña</label>
          <b-form-input id="input-8" v-model="usuario.passwordPlain" placeholder="" required></b-form-input>
        </div>
      </div>
      <hr />
      <h4>DATOS PERSONALES</h4>
      <div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold">Nombres</label>
          <b-form-input id="input-4" v-model="usuario.nombres" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Apellido Paterno</label>
          <b-form-input id="input-5" v-model="usuario.apellidoPaterno" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Apellido Materno</label>
          <b-form-input id="input-6" v-model="usuario.apellidoMaterno" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold"> Tipo de Documento</label>
          <select class="form-control" v-model="usuario.tipoDocumentoId">
            <option :value="null">- Seleccionar -</option>
            <option v-for="tipoDocumento in tipoDocumentos" :value="tipoDocumento.parametroId" :key="tipoDocumento.parametroId">{{ tipoDocumento.codigo }} - {{ tipoDocumento.descripcion }}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Nro. Documento</label>
          <b-form-input id="input-7" v-model="usuario.nroDocumento" placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold"> Rol</label>
          <select class="form-control" v-model="usuario.rolId" required>
            <option :value="null">- Seleccionar -</option>
            <option v-for="rol in roles" :value="rol.rolId" :key="rol.rolId">{{ rol.nombre }}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Celular</label>
          <b-form-input id="input-8" v-model="usuario.celular" placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Correo Electrónico</label>
          <b-form-input id="input-9" v-model="usuario.correoElectronico" placeholder="" required></b-form-input>
          <p class="text-muted ">A este correo se enviarán los datos de acceso.</p>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-2" style="align-self: center;">
          <span class="font-weight-bold">Eliminar</span>
        </div>
        <div class="col-md-10">
          <b-form-checkbox id="checkbox-1" size="lg" v-model="usuario.esEliminado"> </b-form-checkbox>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click.prevent="handleModalClose()"><i class="fa fa-ban"></i> Cancelar</button>
      <button type="button" class="btn btn-primary font-weight-bold" @click.prevent="doSave()"><i class="fa fa-save"></i> Guardar</button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-usuario",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    usuarioId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    usuario: {
      userName: "",
      passwordPlain: "",
      usuarioId: null,
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      nroDocumento: "",
      rolId: null,
      celular: "",
      correoElectronico: "",
      tipoDocumentoId: null,
      esEliminado: false,
    },
    roles: [],
    tipoDocumentos: [],
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      debugger;
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-usuario")) {
        return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
      }

      var model = {
        ...this.usuario,
      };

      this.estaGuardando = true;
      await this.$http.post(this.apiRoute("User", "AddEditUsuario"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.sweetAlert(response.body.message, "success");
          this.$emit("onSave", response.body.data);
          this.mostrarModal = false;
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
    },
    fetchUsuario: function(usuarioId) {
      if (usuarioId) {
        this.$http
          .get(this.apiRoute("User", "GetUsuarioById"), {
            params: {
              UsuarioId: usuarioId,
            },
          })
          .then(function(response) {
            this.usuario = response.body.data;
          });
      }
    },
    fetchRoles: function() {
      this.$http.post(this.apiRoute("User", "ListRoles")).then(function(response) {
        this.roles = response.body.data.items;
      });
    },
    fetchTipoDocumentos: function() {
      debugger;
      var code = "TIPO_DOCUMENTO";
      this.$http
        .get(this.apiRoute("Parameter", "ListParametros"), {
          params: {
            Code: code,
          },
        })
        .then(function(response) {
          this.tipoDocumentos = response.body.data;
        });
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.usuario.usuarioId = this.usuarioId;
    this.initParsley();
    this.fetchTipoDocumentos();
    this.fetchRoles();
    this.fetchUsuario(this.usuario.usuarioId);
  },
  destoyed: function() {},
};
</script>
